
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-search-form {
        height: 32px;
        border-radius: 2px 2px 0 0;
        width: 100%;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 2px;
            background-image:
                linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-primary 50%, $nu-primary 100%);
            background-position-y: 100%;
            background-size: 100% 200%;
            background-repeat: no-repeat;
            transition: background-position-y 0.2s ease;
        }

        &:focus-within {
            &::after {
                background-position-y: 0;
            }
        }

        &__input {
            background-color: $transparent;
            border: none;
            height: 100%;
            width: calc(100% - 32px);

            &:focus {
                outline: 0;
                &::placeholder {
                    color: $nu-gray;
                }
            }

            &::placeholder {
                color: $nu-primary;
            }
        }

        &__icon-container {
            cursor: pointer;
            height: 100%;
            display: inline-flex;
            place-items: center;
        }

        &__icon {
            margin: 0 $nu-spacer-1;
        }
    }
