* {
    box-sizing: border-box;
    /* Change scrollbar styling on Firefox */
    scrollbar-color: $nu-gray--medium transparent;
    scrollbar-width: thin;
}

html {
    background-color: $nu-secondary;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

video {
    max-width: 100%;
    height: auto;
}

// prevent default inset border style
hr {
    @include horizontal-rule();
}

a {
    cursor: pointer;
}
