
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-header-utils {
        align-items: center;
        justify-content: space-between;

        & {
            display: inline-flex;
        }

        &__account {
            height: 48px;
            width: 40px;

            @include breakpoint(medium) {
                width: 48px;
                margin-right: $nu-spacer-1pt5;
            }
        }

        &__link {
            justify-content: center;
            align-items: center;
        }

        &__account {
            display: inline-flex;
            justify-content: center;
            align-items: center;

            .c-tooltip {
                &__popover {
                    width: 360px;
                }

                &__content {
                    padding-top: 0;
                    box-shadow: none;

                    &::before {
                        display: none;
                    }
                }

                &__body {
                    padding: 0
                }
            }

            .c-icon {
                transform-origin: center bottom;
            }

            &.is-animating {
                .c-icon {
                    animation-name: accountHover;
                    animation-duration: 700ms;
                    animation-iteration-count: 1;
                    animation-timing-function: ease;
                }
            }

            @keyframes accountHover {
                0%   { transform: scale(1)      translateY(0);    }
                27%  { transform: scale(1, 0.9) translateY(0);    }
                45%  { transform: scale(1, 1.1) translateY(-4px); }
                78%  { transform: scale(1, .9)  translateY(0);    }
                100% { transform: scale(1)      translateY(0);    }
            }
        }

        &__launcher {
            height: 48px;
            width: 40px;

            @include breakpoint(medium) {
                width: 48px;
            }
        }

        &__menu {
            border-top: 1px solid $nu-gray--light;
        }

        &__link {
            margin-right: $nu-spacer-3;
            display: none;

            @include breakpoint(large) {
                display: inline-flex;

                &:nth-last-child(1 of &) {
                    margin-right: $nu-spacer-1pt5;
                }
            }
        }

        &__anonymous-cta {
            margin: 0 $nu-spacer-1;

            @include breakpoint(medium) {
                margin: 0;
            }
        }
    }
