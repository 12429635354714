
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    @include js-breakpoint-support();

    html.scroll-lock {
        &, body {
            height: var(--vh100);
            overflow: hidden;
            box-sizing: border-box;
        }
    }

    .c-app {
        &.is-scroll-locked {
            position:fixed;
            height: var(--vh100);
            overflow: hidden;
            left: 0;
            right: 0;
        }

        &__container {
            $this: &;

            display: flex;
            flex-direction: column;
            min-height: var(--vh100);

            &__jump-links {
                &--focused ~ #{$this}__header,
                &--focused + #{$this}__main-content .c-slide-layout__header {
                    top: $nu-spacer-3;
                }
            }
        }
    }
