.c-form {

    $this-form: &;

    &__field {
        margin-bottom: $nu-spacer-2;
    }

    &__error {
        @include error-text;
    }

    &__field-container {
        text-align: left;
        width: 100%;
        min-width: 100px;
        padding-top: $nu-spacer-2;
    }

    &__input-container {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            height: 1px;
            background-image:
                linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-gray 50%, $nu-gray 100%);
            background-position-y: 0;
            background-size: 100% 200%;
            background-repeat: no-repeat;
            transition:
                background-position-y 0.2s ease,
                background-image 0.2s ease;
        }

        &:focus-within,
        &--error,
        &--error:focus {
            &::after {
                bottom: -2px;
                height: 2px;
                background-image:
                    linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-primary 50%, $nu-primary 100%);
            }
        }

        &--textarea:focus-within {
            &::after {
               bottom: 6px;
            }
        }

        &--dirty {
            #{$this-form}__input::placeholder {
                transition: opacity 180ms ease-out;
            }
        }

        &--loading {
            #{$this-form}__input,
            #{$this-form}__label {
                color: $nu-gray--medium;
            }

            &:focus-within::after,
            &--error,
            &--error:focus,
            &::after {
                background-image:
                    linear-gradient(to top, $transparent 0%, $transparent 50%, $nu-gray--medium 50%, $nu-gray--medium 100%);
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus {
                color: $nu-gray--medium;
                -webkit-text-fill-color: $nu-gray--medium;
            }
        }

        &--sort {
            color: $nu-gray;
            height: 40px;
            border-radius: 2px;
            background-color: $nu-white;
            padding: $nu-spacer-1pt5 $nu-spacer-1pt5;
            display: flex;
            align-items: center;
            overflow: hidden;
        }

        &--textarea {
            &::after {
                bottom: -2px;
                height: 2px;
                background: transparent;
                background-size: 100% 200%;
            }
        }

        &--character-count {
            position: absolute;
            right: $nu-spacer-1pt5;
            bottom: $nu-spacer-2;
        }
    }


    &__input {
        $this-input: &;

        background-color: $transparent;
        border: none;
        border-radius: 0;
        caret-color: $nu-primary;
        color: $nu-black;
        height: 30px;
        width: 100%;

        &:not(&--sort):not(#{$this-form}__textarea) {
            padding: 0 $nu-spacer-1pt5;
        }

        &[disabled] {
            color: $nu-gray--medium;
        }

        &--error,
        &--error:focus {
            padding-top: 1px;
        }

        &::placeholder {
            color: $nu-gray;
            opacity: 0;
            transition: opacity 100ms ease-in;
            pointer-events: none;
        }

        &:focus::placeholder {
            opacity: 1;
            transition: opacity 200ms ease-in;
        }
    }

    &__label {
        display: block;
        margin-bottom: $nu-spacer-0pt5;

        &--floating, // floating label looks like --dynamic, but is always above the input
        &--dynamic {
            color: $nu-gray;
            margin-bottom: 0;
            margin-left: $nu-spacer-1pt5;
            pointer-events: none;
            user-select: none;
            position: absolute;
        }

        &--floating {
            @extend .o-text--caption;

            transform: translateY(-20px);
            position: absolute;
        }

        &--dynamic {
            transform: translateY(-#{$nu-spacer-3});
            transition: all 180ms ease-out;
            will-change: transform, color, font-size;
        }
    }

    &__input {
        &:focus,
        #{$this-form}__input-container--dirty & {
            & ~ .c-form__label--dynamic {
                @extend .o-text--caption;

                transform: translateY(-#{$nu-spacer-6});
                transition: all 180ms ease-in;
            }
        }
    }

    &__inline-button {
        @extend .u-text--no-select;

        align-items: center;
        bottom: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        position: absolute;
        right: $nu-spacer-1;
    }

    &__inline-text,
    &__inline-image {
        margin-bottom: $nu-spacer-1;
    }

    &__inline-image {
        height: 25px;
        width: 25px;
    }

    &__inline-text {
        color: $nu-primary;
    }

     &__inline-loader {
        width: 20px;
   }

    &__supporting-text {
        margin-top: $nu-spacer-0pt5;

        &--error {
            @extend .o-text--error;
        }
    }

    &__subtext-error-container {
        vertical-align: middle;
    }

    &__subtext-success-container {
        align-items: baseline;
        color: $nu-primary;
        display: flex;
    }

    &__subtext-icon {
        @include error-icon;
    }

    &__select {
        border-radius: 0;
        appearance: none;

        &--sort {
            padding-right: $nu-spacer-3;
            background-position: center right;
            border: none;
            margin: 0;
        }

        .o-text--caption & {
            @extend .o-text--caption;
        }
    }

    &__select-dropdown-arrow {
        position: absolute;
        top: 0;
        right: $nu-spacer-2;
        bottom: 0;
        margin: auto;
        pointer-events: none;
    }

    &__textarea {
        border: none;
        height: auto;
        padding: $nu-spacer-1 $nu-spacer-1pt5;
        background: $nu-white;

        &--no-resize {
            resize: none;
        }

        &::placeholder {
            opacity: 1;
        }
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

// Added manually because autoprefixer doesn't add prefixes for 'autofill'
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  color: $nu-gray;
  -webkit-text-fill-color: $nu-gray;
  transition: background-color 5000s ease-in-out 0s;
}
