
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-accessibility-jump-links {

        &__jump-link {
            position: absolute;
            left: -99999px;

            &:focus {
                position: relative;
                left: 0;
                padding-left: $nu-spacer-3;
                z-index: 999;
                background: $nu-secondary;
                color: $nu-primary;
                text-decoration: underline;
                outline: none !important;

                @include breakpoint(medium) {
                    padding-left: $nu-spacer-4;
                }
            }
        }
    }
