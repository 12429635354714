
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    


.c-common-header {
    width: 100%;
    max-width: 100vw;
    height: 48px;
    display: flex;
    justify-content: space-between;
    background-color: $nu-white;
    padding: 0 $nu-spacer-2;

    &--fixed {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: map-get($zindex, topnav);
    }

    @include breakpoint(medium) {
        padding: 0 $nu-spacer-3;
    }

    @include breakpoint(large) {
        height: 64px;
    }

    &__logo-container {
        display: flex;
        justify-content: center;
        justify-self: center;
        flex: 1;
    }

    &__logo {
        height: 16px;
        fill: $nu-primary;

        @include breakpoint(large) {
            height: 24px;
        }
    }

    &__links {
        display: flex;
        align-self: center;
        position: absolute;
        right: $nu-spacer-2;

        @include breakpoint(medium) {
            right: $nu-spacer-3;
        }
    }

    &__link {
        display: inline-flex;
        margin-right: $nu-spacer-3;

        &:last-child {
            margin-right: 0;
        }
    }
}

