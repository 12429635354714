@font-face {
    font-family: 'Ginto Nord';
    font-style:  normal;
    font-weight: $font-weight-bold;
    src: url($ginto-directory + 'Bold.woff2') format('woff2'),
         url($ginto-directory + 'Bold.woff')  format('woff'),
         url($ginto-directory + 'Bold.ttf')   format('truetype'),
}

@font-face {
    font-family: 'Sharp Grotesk 20';
    font-style:  normal;
    font-weight: $font-weight-normal;
    src: url($sharp-directory + 'Book.woff2') format('woff2'),
         url($sharp-directory + 'Book.woff')  format('woff'),
         url($sharp-directory + 'Book.ttf')   format('truetype'),
}

@font-face {
    font-family: 'Sharp Grotesk 20';
    font-style:  normal;
    font-weight: $font-weight-medium;
    src: url($sharp-directory + 'Medium.woff2') format('woff2'),
         url($sharp-directory + 'Medium.woff')  format('woff'),
         url($sharp-directory + 'Medium.ttf')   format('truetype'),
}

/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: https://www.linotype.com
*/
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=2772b6d6-9116-4066-aa30-c302b5d0df1e&fontids=5171755,5203659,5213336,5327567");

@font-face {
    font-family: 'Americana';
    font-style:  normal;
    font-weight: $font-weight-bold;
    src: url($americana-directory + 'Bold.woff2') format('woff2'),
         url($americana-directory + 'Bold.woff')  format('woff');
}

/***********************
 *** Utility Classes ***
 ***********************/

.u {
    &-text {
        // ** Alignments **
        // Must be used on the parent element of the text you wish to align
        &--left {
            text-align: left !important;
        }

        &--center {
            text-align: center !important;
        }

        &--right {
            text-align: right !important;
        }

        // ** Colors **
        &--color-primary {
            color: $nu-primary !important;
        }

        &--color-secondary {
            color: $nu-secondary !important;
        }

        &--black {
            color: $nu-black !important;
        }

        &--gray {
            color: $nu-gray !important;
        }

        &--medium-gray {
            color: $nu-gray--medium !important;
        }

        &--light-gray {
            color: $nu-gray--light !important;
        }

        &--white {
            color: $nu-white !important;
        }

        // ** Other Effects **
        &--no-select {
            // disallows text selection; useful for ui elements such as button text that may be confusing for the
            // user to be able to highlight
            user-select: none !important;
        }

        &--no-wrap {
            // prevents a section of text from wrapping. Useful for preventing a single long word or phrase from
            // being displayed on multiple lines (particularly hyphenated words, which browsers will break by default)
            white-space: nowrap !important;
        }

        &--no-style {
            font-style: normal;
        }
    }

    &-display {
        &--block {
            display: block !important;
        }
    }

    &--no-wrap {
        // prevents a section of text from wrapping. Useful for preventing a single long word or phrase from
        // being displayed on multiple lines (particularly hyphenated words, which browsers will break by default)
        white-space: nowrap !important;
    }
}



/*******************
 *** Text Styles ***
 *******************/

html {
    color: $nu-black;
    // the baseline for all rem values; 1rem = 10px
    font-size: 10px;
    text-size-adjust: 100%;
}

body,
input,
textarea,
select {
    @include body-copy;

}

input,
textarea,
select {
    @include touch-device {
        // prevent iOS from zooming in on form fields on focus
        font-size: 16px;
    }
}

a {
    color: $nu-primary;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;

    &:active {
        color: $nu-gray;
    }

    &.disabled {
        cursor: default;
        color: $nu-gray--medium;

    }
}

label {
    @include label;
}

.o-text {
    // for edge cases where the default text styling does not automatically get applied
    &--body-copy {
        @include body-copy;
    }

    &--error {
        @include error-text;
    }

    &--label {
        @include label;
    }

    &--link {
        $this: &;

        color: $nu-primary;
        cursor: pointer;
        border-bottom: 1px solid $transparent;
        padding-bottom: 2px;

        &:hover,
        &.is-active,
        a:hover & {
            border-bottom-color: $nu-primary;
        }

        &:focus {
            outline: none;
        }

        &:active,
        a:active & {
            color: $nu-gray;
            border-bottom-color: $nu-gray;
        }

        &[disabled] {
            &,
            &:active,
            &:focus,
            &:hover {
                border-bottom-color: $nu-gray--medium;
                color: $nu-gray--medium;
                cursor: not-allowed;
                text-decoration: none;
            }
        }

        &.is-light {
            color: $nu-white;

            &:hover,
            &:active,
            &:focus,
            &.is-active {
                border-bottom-color: $nu-white;
            }
        }

        &.is-inline {
            text-decoration: underline;

            &,
            &:hover,
            &:active,
            &:focus {
                border: 0;
            }
        }
    }

    &--button {
        font-family: $typeface-sharp;
        font-size: 1.4rem;
        font-weight: $font-weight-normal;
        letter-spacing: 0.3px;
        line-height: 24px;
    }

    &--caption {
        @include caption;
    }

    @at-root h1,
    &--heading-1 {
        @include heading-1;
    }

    @at-root h2,
    &--heading-2 {
        @include heading-2;
    }

    @at-root h3,
    &--heading-3 {
        @include heading-3;
    }

    @at-root h4,
    &--heading-4 {
        @include heading-4;
    }

    @at-root h5,
    &--heading-5 {
        @include heading-5;
    }

    @at-root h6,
    &--heading-6 {
        @include heading-6;
    }

    &--title-1 {
        @include title-1;
    }

    &--title-2 {
        @include title-2;
    }

    &--title-3 {
        @include title-3;
    }

    &--title-4 {
        @include title-4;
    }
}

// Normalize password field obfuscation dots (Webkit uses smaller unicode bullet by default)
input[type="password"] {
    font: small-caption;
    font-size: 16px
}

* {
    &::placeholder {
        font-size: 1.4rem;
        // prevent some browsers from reducing opacity by default
        opacity: 1;
    }
}

// bulleted list
.o-list {
    &--bulleted{
        &::before {
            font-size: 10px;
            content: '\2022'; // Unicode Bullet
        }
    }
}
