
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-toast-manager {
    width: 100%;
    position: fixed;
    z-index: map-get($zindex, toast-notifications);
    bottom: $nu-spacer-3;
    pointer-events: none;

    &__column {
        position: relative;
    }

    &__notification {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

// toast animations
.toast-notification {
    &-move, /* apply transition to moving elements */
    &-enter-active,
    &-leave-active {
        transition: all 0.5s ease;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
        transform: translateY(100px);
        -webkit-transform: translateY(100px);
    }
}
