$grid-cols: 12;

.o-container {
    position: relative;
     // Separates main content from footer
    padding: $nu-spacer-2 0 $nu-spacer-12 0;

    &--no-bottom-padding {
        padding-bottom: 0;
    }

    &--no-top-padding {
        padding-top: 0;
    }

    &--mobile-webview {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }
}

// Default page row
.o-row {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: $nu-spacer-2;
    margin: 0 auto;
    max-width: 1441px;
    padding: 0 $nu-spacer-2;

    @include breakpoint(extra-small) {
        &--collapse-small {
            padding: 0;
        }
    }

    @include breakpoint(medium) {
        padding: 0 $nu-spacer-4 0;

        &--collapse-medium {
            padding: 0;
        }
    }

    @include breakpoint(large) {
        padding: 0 $nu-spacer-8 0;
        grid-column-gap: $nu-spacer-3;

        &--collapse-medium {
            padding: 0;
        }
    }

    // Add class o-row--nested to o-row to reset padding on nested grids
    &--nested {
        padding: 0;
        margin: 0;
    }

    // Switches the order of the layout from the source
    // Because of accessibility challenges, this should likely be avoided
    &--reverse {
        direction: rtl;

        // PERF: Could change to > .column and remove the mapping on each generated class if need to save 3kb
    }

    &--fill-content {
        grid-auto-flow: dense;
    }

    &--no-gap {
        grid-column-gap: 0;
    }
}

// This allows for full bleed rows
.o-row-full {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: $nu-spacer-2;

    @include breakpoint(large) {
        grid-column-gap: $nu-spacer-3;
    }

    &--no-gap {
        grid-column-gap: 0;
    }
}

// Creates the grid classes, breakpoints, and modifiers
// ex: o-extra-small--12, o-large--10

// Reverse grid
// To reverse, we set the direction of the row from rtl,
// We then need to set back the inner divs to ltr
@each $key, $bp in $breakpoints  {
    @if $key == extra-small {
        @for $i from 0 through $grid-cols {
            @if ($i == 0) {
                .o-#{$key}--#{$i} { display: none; }
            } @else {
                .o-#{$key}--#{$i} {
                    display: block;
                    grid-column-end: span $i;
                }
                .o-#{$key}--offset-#{$i} { grid-column-start: ($i + 1); }
                .o-row--reverse .o-#{$key}--#{$i} { direction: ltr; }
            }
        }
    } @else {
        @include breakpoint(#{$key}) {
            @for $i from 0 through $grid-cols {
                @if ($i == 0) {
                    .o-#{$key}--#{$i} { display: none; }
                    .o-#{$key}--offset-#{$i} { grid-column-start: (1); }
                } @else {
                    .o-#{$key}--#{$i} {
                        display: block;
                        grid-column-end: span $i;
                    }
                    .o-#{$key}--offset-#{$i} { grid-column-start: ($i + 1); }
                    .o-row--reverse .o-#{$key}--#{$i} { direction: ltr; }
                }
            }
        }
    }
}

.o-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.o-flex-vertical-center {
    display: flex;
    align-items: center;
}

.o-flex-horizontal-center {
    display: flex;
    justify-content: center;
}

.o-flex-horizontal-right {
    display: flex;
    justify-content: flex-end;
}

.o-flex-horizontal-left {
    display: flex;
    justify-content: flex-start;
}

.o-flex-horizontal-space-between {
    display: flex;
    justify-content: space-between;
}
