
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-footer-help {
        $this: &;

        margin-bottom: $nu-spacer-4;
        flex-direction: column;

        @include breakpoint(medium) {
            flex-direction: row;
            margin-bottom: 0;
        }

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        &,
        &__link-group-container,
        &__link-group-header {
            display: flex;
        }

        &__link-group-container {
            flex-direction: column;
            flex-basis: 100%;

            &.is-open {
                #{$this}__link-group-toggle {
                    &--open {
                        display: none;
                    }

                    &--close {
                        display: block;
                    }
                }
            }

            &:not(.is-open),
            &.is-open {
                @include breakpoint(medium) {
                    #{$this}__link-group-toggle {
                        &--open,
                        &--close {
                            display: none;
                        }
                    }
                }
            }
        }

        &__link-group-header {
            justify-content: space-between;
            align-items: center;
            height: $nu-spacer-6;

            cursor: pointer;

            @include breakpoint(medium) {
                height: auto;
                cursor: auto;
                margin-bottom: $nu-spacer-2;

                &:focus {
                    outline: none;
                }
            }
        }

        &__link-group {
            height: 0;
            overflow: hidden;
            margin: 0 0 0 $nu-spacer-1;

            &.is-open {
                height: auto;
                margin: $nu-spacer-1pt5 0;
            }

            @include breakpoint(medium) {
                height: auto;

                &,
                &.is-open {
                    margin-left: 0;
                }
            }
        }

        &__link-group-toggle {
            display: block;
            margin: $nu-spacer-2;

            @include breakpoint(medium) {
                display: none;
            }
        }

        &__link-container {
            &:not(:last-of-type) {
                margin-bottom: $nu-spacer-1;
            }
        }
    }
