
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-header {
    &__search-container {
        display: none;

        @include breakpoint(large) {
            display: inline-block;
        }
    }

    &__menu-label-text {
        margin-left: $nu-spacer-1;

        &--desktop {
            display: none;

            @include breakpoint(large) {
                display: block;
            }
        }

        &--mobile {
            display: block;

            @include breakpoint(large) {
                display: none;
            }
        }
    }
}
