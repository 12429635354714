$illustration-sizes: (
    32px: 32px,
    40px: 40px,
    48px: 48px,
    56px: 56px,
    64px: 64px,
    80px: 80px,
    96px: 96px,
    104px: 104px,
    128px: 128px,
    176px: 176px,
    200px: 200px,
    256px: 256px,
    320px: 320px,
);
