.c-error {
    padding: $nu-spacer-6 0;

    &__icon {
        height: 178px;
    }
}

.o-error-page {
    background: $nu-secondary;

    &__header {
        display: block !important;
    }
}
