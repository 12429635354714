
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    $modal-transition-duration: 400ms;
    $modal-content-shift: 100%;

    .js-height-reset {
        height: unset !important;
    }

    .o-modal-overlay {
        @include overlay();
        z-index: map-get($zindex, modal-overlay);
    }

    .o-modal {
        position: relative;
        height: 100%;

        &__header,
        &__prev,
        &__close,
        &__header-button-container,
        &__header-title-container {
            display: inline-flex;
            align-items: center;
            outline-offset: -4px;
        }

        &__header {
            background-color: $nu-white;
            justify-content: space-between;
            height: 48px;
            width: 100%;
            border-bottom: 1px solid $nu-gray--light;

            &--no-title {
                background-color: unset;
            }
        }

        $header-btn-clickable: 48px;

        &__prev,
        &__close {
            cursor: pointer;
            justify-content: center;
            height: $header-btn-clickable;
            width: $header-btn-clickable;
        }

        &__header-button-container,
        &__header-title-container {
            height: 100%;
        }

        &__header-button-container {
            width: $header-btn-clickable;
        }

        &__header-title-container {
            width: calc(100% - #{$header-btn-clickable});
            justify-content: center;
        }

        &__header-image {
            height: 100%;
            display: flex;
            align-items: center;
            padding-inline: $nu-spacer-2;
            outline-offset: -4px;

            svg {
                height: 16px;
                fill: $nu-primary;
            }

            &__logo {
                cursor: pointer;
            }
        }

        &__outer {
            display: flex;
            flex-direction: column;

            position: fixed;
            top: 0;
            left: 0;
            z-index: map-get($zindex, modal);
            overflow: hidden;
            // Added manually because autoprefixer doesn't add '-webkit-overflow-scrolling'
            -webkit-overflow-scrolling: touch;
            width: 100%;
            height: 100%;
            background-color: $nu-gray--light;

            &.is-light:not(.is-styletype-bottom) {
                background-color: $nu-white;
            }

            &.is-styletype-bottom,
            &.is-styletype-bottom-extra-short,
            &.is-styletype-bottom-short,
            &.is-styletype-bottom-fullscreen {
                top: unset;
                right: 0;
                bottom: 0;
            }

            &.is-styletype-bottom {
                height: 85%;
            }

            &.is-styletype-bottom-extra-short {
                height: 40%;
            }

            &.is-styletype-bottom-short {
                height: 65%;
            }

            &.is-styletype-right, &.is-styletype-left {
                left: unset;
                height: var(--vh100);
                width: 90%;
                box-shadow: 0 3px 3.5px 0 rgba(0, 0, 0, 0.25);

                @include breakpoint(medium) {
                    width: 424px;
                }
            }

            &.is-styletype-right {
                right: 0;
            }

            &.is-styletype-center {
                @include breakpoint(medium) {
                    top: 20%;
                    left: 20%;
                    width: 60%;
                    height: 60%;
                }
            }

            &.is-styletype-top {
                top: 0;
                height: 60%;
            }
        }

        &__inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: $nu-gray--light;

            .is-styletype-top & {
                height: 100%;
                overflow: hidden;
            }

            &.is-light:not(.is-styletype-bottom) {
                background-color: $nu-white;
            }

            transform: translate3d(-$modal-content-shift, 0, 0);
            opacity: 0;
            transition:
                opacity $modal-transition-duration ease,
                transform $modal-transition-duration ease;
            overflow-y: scroll;

            &.is-shown {
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }

            &.has-padding {
                padding: $nu-spacer-3;
            }

            .is-styletype-center & {
                height: calc(var(--vh100) - #{var(--titleBarHeight)});
            }

            .is-styletype-bottom &,
            .is-styletype-bottom-short &,
            .is-styletype-bottom-extra-short &,
            .is-styletype-fullscreen &,
            .is-styletype-bottom-fullscreen & {
                background-color: $nu-secondary;
            }

            .is-styletype-bottom & {
                height: calc(calc(var(--vh) * 85) - #{var(--titleBarHeight)});

                &:not(.no-padding) {
                    padding: $nu-spacer-6 $nu-spacer-2 $nu-spacer-8;
                    overflow-x: hidden;

                    @include breakpoint(medium) {
                        padding-left: $nu-spacer-4;
                        padding-right: $nu-spacer-4;
                    }

                    @include breakpoint(large) {
                        padding: $nu-spacer-8 $nu-spacer-8 $nu-spacer-16;
                    }
                }
            }

            .is-styletype-bottom-fullscreen & {
                height: calc(var(--vh100) - #{var(--titleBarHeight)});

                &:not(.no-padding) {
                    padding: $nu-spacer-6 $nu-spacer-2 $nu-spacer-8;
                    overflow-x: hidden;

                    @include breakpoint(medium) {
                        padding-left: $nu-spacer-4;
                        padding-right: $nu-spacer-4;
                    }

                    @include breakpoint(large) {
                        padding: $nu-spacer-8 $nu-spacer-8 $nu-spacer-16;
                    }
                }
            }

            .is-styletype-bottom-short & {
                height: calc(calc(var(--vh) * 65) - #{var(--titleBarHeight)});
            }

            .is-styletype-bottom-extra-short & {
                height: calc(calc(var(--vh) * 40) - #{var(--titleBarHeight)});
            }

            .is-styletype-right &, .is-styletype-left & {
                height: calc(var(--vh100) - #{var(--titleBarHeight)});
            }

            .is-styletype-fullscreen & {
                height: 100%;
            }

            &.is-content-centered {
                align-items: center;
                display: flex;
                height: 100%;

                & > * {
                    flex: 1;
                }
            }
        }
    }

    // Modal Container Animation (CENTER, FULLSCREEN)
    .modal-container-center, .modal-container-fullscreen {
        &-enter-from,
        &-leave-to {
            opacity: 0;
            transform: scale(1.1);
        }

        &-enter-active,
        &-leave-active {
            transition:
                opacity $modal-transition-duration ease,
                transform $modal-transition-duration ease;
        }
    }

    // Modal Container Animation (RIGHT, BOTTOM, LEFT)
    .modal-container-right,
    .modal-container-bottom,
    .modal-container-bottom-extra-short,
    .modal-container-bottom-short,
    .modal-container-bottom-fullscreen,
    .modal-container-top,
    .modal-container-left {
        &-enter-from,
        &-leave-to {
            &.o-modal-overlay {
                background-color: $transparent;
            }
        }

        &-enter-to,
        &-leave-from {
            &.o-modal-overlay {
                background-color: $modal-overlay;
            }
        }

        &-enter-active,
        &-leave-active {
            &.o-modal-overlay {
                transition: background $modal-transition-duration ease;
            }

            .o-modal__outer {
                transition: transform $modal-transition-duration ease;
            }
        }
    }

    // Modal Container Animation (RIGHT, LEFT)
    .modal-container-right, .modal-container-left {
        &-enter-to,
        &-leave-from {
            .o-modal__outer {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    // Modal Container Animation (RIGHT)
    .modal-container-right {
        &-enter-from,
        &-leave-to {
            .o-modal__outer {
                transform: translate3d(100%, 0, 0);
            }
        }
    }

    // Modal Container Animation (LEFT)
    .modal-container-left {
        &-enter-from,
        &-leave-to {
            .o-modal__outer {
                transform: translate3d(-100%, 0, 0);
            }
        }
    }

    // Modal Container Animation - bottom-aligned modals
    .modal-container-bottom,
    .modal-container-bottom-extra-short,
    .modal-container-bottom-short,
    .modal-container-bottom-fullscreen {
        &-enter-from,
        &-leave-to {
            .o-modal__outer {
                transform: translateY(100%);
            }
        }

        &-enter-to,
        &-leave-from {
            .o-modal__outer {
                transform: translateY(0);
            }
        }
    }

    // Modal Container Animation - top modals
    .modal-container-top {
        &-enter-from,
        &-leave-to {
            .o-modal__outer {
                transform: translateY(-100%);
            }
        }

        &-enter-to,
        &-leave-from {
            .o-modal__outer {
                transform: translateY(0);
            }
        }
    }

    // Modal Contents Animation
    .modal-contents {
        &-enter-from.o-modal__inner.is-stacked,
        &-leave-to.o-modal__inner.is-stacked {
            transform: translate3d($modal-content-shift, 0, 0);
            opacity: 0;
        }
    }
