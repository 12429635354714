// generate illustration-sizes
@each $key, $value in $illustration-sizes {
    .o-illustration--#{$key} {
        width: $value;
    }
}

.o-illustration {
    display: inline-block;

    &__img {
        width: 100%;
    }
}
