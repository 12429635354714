
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-legal-footer {
        background-color: $nu-white;
        flex-wrap: wrap;

        &__ul {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: $nu-spacer-1 0;
        }

        &__li {
            display: block;
            position: relative;
            margin: 0 $nu-spacer-2;

            &:not(:last-of-type)::after {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                right: -($nu-spacer-2);
                margin: auto;
                width: 1px;
                height: 70%;
                background-color: $nu-gray--medium;
            }
        }
    }
