
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-header {
    $this: &;

    display: flex;
    padding: 0 $nu-spacer-1;
    max-width: 100vw;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 48px;
    z-index: map-get($zindex, topnav);
    background-color: $nu-white;
    color: $nu-primary;

    @include breakpoint(medium) {
        height: 64px;
        padding: 0 $nu-spacer-3;
    }

    &__left-nav,
    &__logo-container,
    &__utils-container {
        height: 100%;

        &,
        #{$this}__menu-container {
            display: flex;
            align-items: center;
        }
    }

    &__logo-container {
        flex-basis: 64px;
    }

    &__left-nav,
    &__utils-container {
        flex-basis: calc(50% - 32px);
    }

    &__left-nav {
        justify-content: flex-start;
        justify-self: flex-start;
    }

    &__search-container {
        display: inline-block;
        margin-left: $nu-spacer-3;
        width: 75%;
    }

    &__logo-container {
        justify-content: center;
        justify-self: center;
    }

    &__utils-container {
        justify-content: flex-end;
        justify-self: flex-end;
    }

    &__logo {
        height: 16px;
        fill: $nu-primary;

        @include breakpoint(medium) {
            height: 24px;
        }
    }
}
