
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-route-container {
        background: $nu-secondary;
        flex: 1 1 auto;
        min-height: 100%;

        &__transition-enter-active,
        &__transition-leave-active {
            transition: opacity 0.5s ease;
        }

        &__transition-enter-from,
        &__transition-leave-to {
            opacity: 0;
        }
    }

    .o-view {
        opacity: 0;
        transition: opacity 500ms ease;

        &--is-shown {
            opacity: 1;
        }
    }
