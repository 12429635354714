
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-navigation-launcher {
        display: inline-flex;
        border: 0 none;
        align-items: center;
        padding: $nu-spacer-1;
        background-color: $transparent;
        transition: background-color 0.2s ease;

        &:hover,
        &:focus[data-focus-visible-added] {
            background-color: $nu-gray--light;
        }

        &,
        * {
            cursor: pointer;
        }

        &__icon svg {
            margin-right: 0;
        }

        &__label {
            display: inline-block;
            @include body-copy;
            color: $nu-primary;
        }
    }
