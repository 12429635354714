
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &,
        *{
            transition-property: all;
            transition-timing-function: ease;
            transition-duration: var(--transitionTiming);
            overflow: visible;
        }

        & > * {
            height: 100%;
            width: 100%;
            overflow: visible;
        }

        &--8px {
            height: 8px;
            width: 8px;
        }

        &--12px {
            height: 12px;
            width: 12px;
        }

        &--16px {
            height: 16px;
            width: 16px;
        }

        &--24px {
            height: 24px;
            width: 24px;
        }

        &--color-primary {
            fill: $nu-primary;
        }

        &--color-secondary {
            fill: $nu-secondary;
        }

        &--color-black {
            fill: $nu-black;
        }

        &--color-gray {
            fill: $nu-gray;
        }

        &--color-medium-gray {
            fill: $nu-gray--medium;
        }

        &--color-light-gray {
            fill: $nu-gray--light;
        }

        &--color-white {
            fill: $nu-white;
        }

        &--orient-right > * {
            transform: rotate(90deg);
        }

        &--orient-down > * {
            transform: rotate(180deg);
        }

        &--orient-left > * {
            transform: rotate(270deg);
        }
    }
