
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-tooltip {
        $this: &;
        position: relative;

        &__launcher-container {
            cursor: pointer;

            &--not-clickable {
                cursor: default;
            }
        }

        &__popover {
            // instead of hiding using v-if/v-show, element must be already rendered prior to being visible in order
            // to calculate the right offset (when alignment === right). Instead, popover is rendered offscreen and
            // invisible to users (incl. those using ARIA)
            position: fixed;
            z-index: -9999999;
            top: -99999px;
            visibility: hidden;
            width: 300px;

            &.is-visible {
                position: absolute;
                z-index: map-get($zindex, modal);
                top: var(--popoverTopMargin);
                left: var(--popoverRightOffset);
                bottom: unset;
                visibility: visible;
                border-color: transparent;
                border-style: solid;
            }

            &--left {
                #{$this}__content::before {
                    left: var(--popoverTriangleOffset);
                }
            }

            &--right {
                #{$this}__content::before {
                    right: var(--popoverTriangleOffset);
                }
            }

            &--above {
                border-bottom: var(--popoverHoverableBorder);

                #{$this}__content {

                    &::before {
                        bottom: -8px;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 8px solid $nu-white;
                    }
                }
            }

            &--below {
                border-top: var(--popoverHoverableBorder);

                #{$this}__content {
                    &::before {
                        top: -8px;
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-bottom: 8px solid $nu-white;
                    }
                }
            }
        }

        &__content {
            @include dropshadow;

            position: relative;
            border-radius: 2px;
            background-color: $nu-white;
            padding-top: $nu-spacer-3;

            &::before {
                // CSS Triangle
                position: absolute;
                content: ' ';
                width: 0;
                height: 0;
            }
        }

        &__header,
        &__body {
            padding-left: $nu-spacer-2;
            padding-right: $nu-spacer-2;
        }

        &__header {
            padding-bottom: $nu-spacer-0pt5;
        }

        &__body {
            padding-bottom: $nu-spacer-3;
            text-align: center;

            &--no-bottom {
                padding-bottom: 0;
            }
        }

        &__footer {
            border-top: 1px solid $nu-gray--light;
            padding: $nu-spacer-1 0;
            width: 100%;
        }
    }
