
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-toast-notification {
    @include dropshadow;

    width: 100%;
    pointer-events: auto;
    min-height: $nu-spacer-6;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        .o-text--link {
            border-bottom-color: $nu-white;
        }
    }

    &__content-wrapper {
        width: 100%;
        color: $nu-white;
        border-radius: 4px;
        background-color: $nu-primary;
        padding: $nu-spacer-1pt5 $nu-spacer-2;

        &--product-image {
            padding-left: $nu-spacer-1pt5;
        }
    }

    &__static-content {
        width: 100%;
        min-width: 0;
    }

    &__image-container {
        display: flex;
        margin-right: $nu-spacer-2;
    }

    &__image-container-inner {
        width: $nu-spacer-4;
        background-color: $placeholder-background;
    }

    &__image {
        width: 100%;
        height: 100%;
    }

    &__icon {
        margin-right: $nu-spacer-2;
    }

    &__link-text {
        margin-left: $nu-spacer-2pt5;
        white-space: nowrap;
    }

    &__description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
    }
}
