@mixin horizontal-rule($color: $nu-gray) {
    border-style: solid;
    border-width: 1px;
    border-top: 0;
    border-color: $color;
}

@mixin pipe($height: $nu-spacer-1pt5) {
    display: inline-flex;
    width: 1px;
    height: $height;
    margin: 0 $nu-spacer-1pt5;
    vertical-align: middle;
    background-color: $nu-gray--medium;
}
