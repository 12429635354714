
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-lazy-image {
        &__fade-in {
            opacity: 0;
            transition: 750ms ease;

            &--loaded {
                opacity: 1;
            }
        }
    }
