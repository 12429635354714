
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-header-unauth-menu {
    padding: $nu-spacer-3;
    background-color: $nu-white;

    &__button {
        width: 100%;
    }
}
