
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-footer {
        background-color: $nu-primary;
        opacity: 0;
        transition: opacity 500ms ease;

        &--show {
            opacity: 1;
        }

        &__logo-container,
        &__socials,
        &__legals {
            display: flex;
        }

        &__logo-container,
        &__socials,
        &__legals {
            justify-content: center;
        }

        &__logo-container,
        &__socials {
            align-items: center;
        }

        &__main-container {
            padding: $nu-spacer-6 $nu-spacer-3 $nu-spacer-4;

            @include breakpoint(large) {
                padding: $nu-spacer-6 $nu-spacer-6 $nu-spacer-4;
                margin: auto;
            }
        }

        &__legals-container {
            background-color: $nu-white;
        }

        &__logo-container,
        &__legals {
            flex-wrap: wrap;
        }

        &__legals {
            margin: $nu-spacer-1 0;
        }

        &__logo-container {
            @include breakpoint(large) {
                justify-content: left;
            }
        }

        &__logo {
            margin-bottom: $nu-spacer-1pt5;

            svg {
                display: block;
                fill: $nu-white;
                height: 20px;
            }
        }

        &__app-store-logo {
            g {
                fill: $nu-black;
            }
        }

        &__copyright {
            flex-basis: 100%;
            text-align: center;
            margin-bottom: $nu-spacer-4;

            @include breakpoint(large) {
                text-align: left;
            }
        }

        &__socials-header {
            text-align: center;

            @include breakpoint(medium) {
                text-align: left;
            }
        }

        &__socials {
            @include breakpoint(medium) {
                justify-content: left;
            }
        }

        &__social-icon-container {
            margin: 0 $nu-spacer-1;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &__rule {
            @include horizontal-rule($nu-white);
            margin: $nu-spacer-1 0;
        }

        &__legal-link {
            display: block;
            position: relative;
            margin: 0 $nu-spacer-2;

            &:not(:last-of-type)::after {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                right: -($nu-spacer-2);
                margin: auto;
                width: 1px;
                height: 70%;
                background-color: $nu-gray--medium;
            }
        }
    }
