
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-product-image--with-outline {
        .focus-visible:focus[data-focus-visible-added] & {
            outline: $focus-outline;
            outline-offset: -4px;
            transition: unset;
        }
    }
