
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

.c-global-loader {
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;

    &__container {
        width: 100vw;
        height: 100%;
    }

    &__animation {
        filter: $nu-filter-color;
        -webkit-filter: $nu-filter-color;
    }
}
